<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="border-0 p-3 d-flex justify-content-center map-container" style="min-height: 60px;" @mouseleave="unsetHoveredStyles">
			<olMap :canvasFeatures="canvasFeatures" @mapReady="getMapContext" @layerAdded="cacheVectorLayer">
				<template #featureControls>
					<div class="toolbar">
						<ul class="nav pt-3 d-flex">
							<li v-b-tooltip.hover.bottom="'Show Beam Footprints'" class="nav-item mb-2 mx-2" @click="toggleTool('footprints')">
								<span class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning toolbar-icon" :class="{ 'btn-active': footprints }">
									<i class="flaticon-layers"></i>
								</span>
							</li>
							<li v-b-tooltip.hover.bottom="'Show Satellite Path'" class="nav-item mr-2" @click="toggleTool('satPaths')">
								<span class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning sat-icon p-2" :class="{ 'btn-active': satPaths }">
									<inline-svg src="/media/svg/custom/sat.svg" />
								</span>
							</li>
							<li v-b-tooltip.hover.bottom="'Show Remote Path'" class="nav-item mr-2" @click="toggleTool('remotePath')">
								<span
									class="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning toolbar-icon p-2"
									:class="{ 'btn-active': remotePath }"
								>
									<i class="flaticon-map-location"></i>
								</span>
							</li>
						</ul>
					</div>
				</template>
			</olMap>

			<!-- OVERLAYS -->
			<!-- SATELLITE OVERLAY -->
			<div
				ref="satOverlay"
				class="satOverlay d-flex justify-content-center align-items-center"
				:style="{
					border: '1px solid ' + hoveredSat.color,
					'box-shadow': '2px 2px 10px -5px #000',
					visibility: overlayVisibility,
				}"
			>
				<div
					class="sat-arrow"
					:style="{
						'border-left': '1px solid ' + hoveredSat.color,
						'border-bottom': '1px solid ' + hoveredSat.color,
					}"
				></div>
				<div class="sat-name px-3">
					{{ hoveredSat.name }}
				</div>
			</div>
			<!-- MARKER OVERLAY -->
			<div
				ref="markerOverlay"
				class="marker-popup"
				:style="{
					visibility: overlayVisibility,
				}"
			>
				<div class="popover-arrow"></div>
				<div
					class="marker-popup-title"
					:style="{
						background: `linear-gradient(200deg, ${color[0]}, ${color[1]})`,
					}"
				>
					<h5>{{ device.Name }}</h5>
				</div>
				<div class="marker-popup-body">
					<div>
						<span>GPS: </span><span class="marker-stat">{{ device.gps.lat }}, {{ device.gps.lon }}</span>
					</div>
					<div>
						<span>Current Network: </span><span class="marker-stat">{{ device.networks[device.currentid].NetworkName }}</span>
					</div>
				</div>
			</div>
		</div>

		<!-- NETWORK LEGEND -->
		<div class="d-flex justify-content-center align-items-center m-2">
			<div class="scroll-note">Click map to zoom with scroll</div>
			<div style="font-size: 1.2em">
				{{ device.Name }}
			</div>
		</div>
		<div id="networks-legend">
			<div class="d-flex justify-content-center flex-wrap">
				<b-table-simple hover responsive>
					<b-thead>
						<b-tr>
							<b-th>Network</b-th>
							<b-th>Longitude</b-th>
							<b-th>Azimuth</b-th>
							<b-th>Elevation</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr
							v-for="net in networks"
							:key="net.NetworkName"
							@mouseover="highlightNetwork(net.NetworkName)"
							@mouseleave="unsetHoveredStyles"
							:class="{ 'highlight-blue': net.currentNet }"
						>
							<b-td>
								<div class="d-flex">
									<div class="network-color mr-2" :style="{ background: `rgba(${net.color.join(',')})` }"></div>
									<div>{{ net.NetworkName }}</div>
								</div>
							</b-td>
							<b-td>{{ net.spacecraft ? net.spacecraft.Longitude : 'Unavailable' }}</b-td>
							<b-td>{{ net.azEl ? net.azEl.azimuth : 'Unavailable' }}</b-td>
							<b-td>{{ net.azEl ? net.azEl.elevation : 'Unavailable' }}</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>
	</div>
</template>

<script>
import { fromLonLat } from 'ol/proj';
import { Icon, Style, Fill, Stroke, Text } from 'ol/style';
import { asArray } from 'ol/color';
import Overlay from 'ol/Overlay';
import { getAzEl } from '@/helpers.js';

//prettier-ignore
const colors = [
	'#0000FF','#FF9933','#660066','#FF3333','#009900','#333333','#99FFFF','#FFCCCC','#666666','#993300','#003300','#FF66FF','#660000','#666600','#66CC99','#FE4543','#9E5D11','#702311','#000099','#990099','#990000','#999900','#009900','#009999','#0033FF','#9900FF','#FF0066','#FF9900','#66FF00','#00FF99','#0066FF',	'#0000FF','#FF9933','#660066','#FF3333','#009900','#333333','#99FFFF','#FFCCCC','#666666','#993300','#003300','#FF66FF','#660000','#666600','#66CC99','#FE4543','#9E5D11','#702311','#000099','#990099','#990000','#999900','#009900','#009999','#0033FF','#9900FF','#FF0066','#FF9900','#66FF00','#00FF99','#0066FF'
];

const setCanvasMarkers = (name, lat, lon, status) => {
	let srcs = {
		OK: '/media/markers/marker_green.png',
		ALARM: '/media/markers/marker.png',
		SCPC: '/media/markers/marker_blue.png',
		DEACTIVATED: '/media/markers/marker_white.png',
		BEAMCHANGE: '/media/svg/Custom/beamswitch.svg',
		default: '/media/markers/marker_grey.png',
	};
	return {
		name: 'markers',
		geojson: {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: fromLonLat([lon, lat]),
					},
					properties: {
						name: name,
						status: status,
						type: 'marker',
					},
				},
			],
		},
		style: new Style({
			image: new Icon({
				anchor: [0.5, 1],
				anchorXUnits: 'fraction',
				anchorYUnits: 'fraction',
				src: srcs?.[status] || srcs['default'],
			}),
		}),
	};
};

const setConfiguredFootprints = networks => {
	let nets = [];
	networks.forEach(function(net) {
		if (net.footprint) {
			nets.push({
				footprint: net.footprint[0],
				id: net.NetworkId,
				name: net.NetworkName,
				color: asArray(net.color),
				type: 'footprint',
			});
		}
	});
	return {
		name: 'footprints',
		...constructPolyGeoJson(nets),
	};
};

const constructPolyGeoJson = networks => {
	let features = networks.map(net => {
		let over = false;
		let under = false;
		let path = net.footprint.path.map(coord => {
			if (coord.longitude > 170) over = true;
			if (coord.longitude < -170) under = true;
			return fromLonLat([coord.longitude, coord.latitude]);
		});
		if (over && under) {
			path = net.footprint.path.map(coord => {
				if (coord.longitude > 0) coord.longitude -= 360;
				return fromLonLat([coord.longitude, coord.latitude]);
			});
		}
		let properties = {};
		for (let prop in net) {
			if (prop !== 'footprint') {
				properties[prop] = net[prop];
			}
		}
		return {
			type: 'Feature',
			geometry: {
				type: 'Polygon',
				coordinates: [path],
			},
			properties,
		};
	});
	return {
		geojson: {
			type: 'FeatureCollection',
			features: features,
		},
		style: feature => {
			return new Style({
				stroke: new Stroke({
					color: feature.get('color'),
					width: 2,
				}),
				fill: new Fill({
					color: [0, 0, 0, 0],
				}),
			});
		},
	};
};

const setSatelliteMarkers = networks => {
	return {
		name: 'satellites',
		geojson: {
			type: 'FeatureCollection',
			features: networks
				.filter(net => net.spacecraft && net.spacecraft.Longitude)
				.map(net => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: fromLonLat([net.spacecraft.Longitude, 0]),
						},
						properties: {
							...net.spacecraft,
							type: 'satellite',
							color: net.currentNet ? 'blue' : 'black',
							pathColor: net.color,
						},
					};
				}),
		},
		style: feature =>
			new Style({
				image: new Icon({
					anchorXUnits: 'fraction',
					anchorYUnits: 'fraction',
					scale: 0.2,
					src: '/media/svg/custom/sat-white.svg',
					color: feature.get('color'),
				}),
			}),
	};
};

const setPathToSatellite = (networks, lat, lon) => {
	return {
		name: 'satPaths',
		geojson: {
			type: 'FeatureCollection',
			features: networks
				.filter(net => net.spacecraft && net.spacecraft.Longitude)
				.map(net => {
					let spacelon = net.spacecraft.Longitude > 170 ? net.spacecraft.Longitude - 360 : net.spacecraft.Longitude;
					return {
						type: 'Feature',
						geometry: {
							type: 'LineString',
							coordinates: [fromLonLat([lon, lat]), fromLonLat([spacelon, 0])],
						},
						properties: {
							color: net.color,
						},
					};
				}),
		},
		style: feature => {
			return new Style({
				stroke: new Stroke({
					color: feature.get('color'),
					width: 2,
				}),
			});
		},
	};
};

const setRemotePath = path => {
	return {
		name: 'remotePath',
		geojson: {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'LineString',
						coordinates: path.map(p => {
							return fromLonLat([p.lon, p.lat]);
						}),
					},
				},
			],
		},
		style: new Style({
			stroke: new Stroke({
				color: 'blue',
				width: 2,
			}),
		}),
	};
};
const overlayColors = {
	OK: ['#c4fce5', '#37c583'],
	ALARM: ['#f7ccd0', '#ED6B75'],
	DEACTIVATED: ['#e2eeff', '#bac3d1'],
	SCPC: ['#bdd9fc', '#659BE0'],
	default: ['#e2eeff', '#bac3d1'],
};

export default {
	name: 'RealtimeMap',
	components: {
		olMap: () => import('@/view/content/lib/ol.vue'),
	},
	props: {
		device: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			map: null,
			canvasFeatures: [],
			vLayers: {},
			footprints: true,
			satPaths: true,
			remotePath: true,
			hovered: [],
			satOverlay: null,
			markerOverlay: null,
			hoveredSat: {
				name: null,
				color: [],
			},
			overlayVisibility: 'hidden',
		};
	},
	computed: {
		networks() {
			if (!this.device) return [];
			let i = 0;
			let nets = [];
			for (let net in this.device.networks) {
				let color = colors[i];
				let currentNet = this.device.currentid === this.device.networks[net].NetModemId;
				nets.push({
					...this.device.networks[net],
					color: asArray(color),
					azEl:
						this.device.networks[net].spacecraft && getAzEl(this.device.gps.lat, this.device.gps.lon, this.device.networks[net].spacecraft.Longitude),
					currentNet,
				});
				i++;
			}
			nets.sort((a, b) => {
				let nameA = a.NetworkName.toUpperCase();
				let nameB = b.NetworkName.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
			return nets;
		},
		color() {
			if (!this.device.status) return overlayColors.default;
			return overlayColors[this.device.status.status];
		},
	},
	methods: {
		async toggleTool(tool) {
			if (this.vLayers[tool]) {
				this[tool] = !this.vLayers[tool].getVisible();
				this.vLayers[tool].setVisible(this[tool]);
			}
		},
		getMapContext(map) {
			this.map = map;
			this.map.getView().setCenter(fromLonLat([this.device.gps.lon, this.device.gps.lat]));
			this.satOverlay = new Overlay({
				element: this.$refs.satOverlay,
				positioning: 'center-left',
				stopEvent: false,
				offset: [22, -16],
			});
			this.markerOverlay = new Overlay({
				element: this.$refs.markerOverlay,
				positioning: 'center-center',
				stopEvent: false,
				offset: [12, -this.$refs.markerOverlay.clientHeight / 2],
			});
			this.overlayVisibility = 'visible';
			this.map.addOverlay(this.satOverlay);
			this.map.addOverlay(this.markerOverlay);
			this.map.on('pointermove', event => this.handlePointermove(event));
		},
		cacheVectorLayer(layer) {
			this.vLayers = { ...layer, ...this.vLayers };
		},
		handlePointermove(event) {
			this.unsetHoveredStyles();
			this.satOverlay.setPosition(undefined);
			this.markerOverlay.setPosition(undefined);
			this.map.forEachFeatureAtPixel(event.pixel, feature => {
				switch (feature.get('type')) {
					case 'footprint':
						if (this.footprints) this.setFootprintHoveredStyle(feature);
						break;
					case 'satellite':
						this.hoveredSat.name = feature.get('SpacecraftName');
						this.hoveredSat.color = `rgba(${feature.get('pathColor').join(',')})`;
						this.satOverlay.setPosition(feature.getGeometry().getCoordinates());
						break;
					case 'marker':
						this.markerOverlay.setPosition(feature.getGeometry().getCoordinates());
						return true;
				}
			});
		},
		setFootprintHoveredStyle(feature) {
			this.hovered.push(feature);
			let color = feature.get('color');
			let fillColor = [...color];
			fillColor[3] = 0.4;
			feature.setStyle(
				new Style({
					stroke: new Stroke({
						color: color,
						width: 3,
					}),
					fill: new Fill({
						color: fillColor,
					}),
					text: new Text({
						text: feature.get('name'),
						font: '20px Arial',
						color: '#FFF',
						stroke: new Stroke({
							color: '#ffffff',
							width: 2,
						}),
					}),
				})
			);
		},
		unsetHoveredStyles() {
			if (this.hovered.length !== 0) {
				this.hovered.forEach(feature => feature.setStyle(undefined));
				this.hovered = [];
			}
		},
		highlightNetwork(networkName) {
			this.vLayers.footprints.getSource().forEachFeature(feature => {
				if (feature.get('name') === networkName) {
					this.setFootprintHoveredStyle(feature);
				}
			});
		},
		load() {
			this.vLayers = {};
			this.canvasFeatures = [
				setCanvasMarkers(this.device.Name, this.device.gps.lat, this.device.gps.lon, this.device.status.status),
				setPathToSatellite(this.networks, this.device.gps.lat, this.device.gps.lon),
				setRemotePath(this.device.gps.path),
				setSatelliteMarkers(this.networks),
				setConfiguredFootprints(this.networks),
			];
		},
	},
	mounted() {
		if (this.device && Object.keys(this.device).length > 0) {
			this.load();
		}
	},
	watch: {
		device: {
			handler: function(newVal) {
				if (newVal && Object.keys(newVal).length > 0) {
					this.load();
				}
			},
		},
	},
};
</script>

<style scoped>
.map-container {
	position: relative;
	min-height: 200px;
	transition: all 300ms ease-in-out;
}
.satOverlay {
	height: 35px;
	position: absolute;
	border-radius: 5px;
	background: white;
}
.sat-name {
	font-weight: 500;
	white-space: nowrap;
}
.toolbar {
	position: absolute;
	z-index: 1;
	top: 0;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}
.toolbar-icon i {
	color: grey;
}
.sat-icon svg {
	fill: grey;
}
.btn-active {
	background: #ffa800;
	border-color: #ffa800;
}
.btn-active:active {
	background: #9e6a01;
	border-color: #ffa800;
}
.btn-active.toolbar-icon i {
	color: white;
}
.btn-active.sat-icon svg,
.sat-icon:hover svg {
	fill: white;
}
#networks-legend {
	min-height: 35px;
}
.network {
	border: 1px solid #fff;
	cursor: pointer;
}
.network:hover {
	border: 1px solid rgba(0, 153, 255, 0.2);
	border-radius: 3px;
	background: rgba(0, 153, 255, 0.1);
}
.network-color {
	display: inline-block;
	height: 20px;
	width: 20px;
	border-radius: 20px;
}
.marker-popup,
.beamchange-popup {
	position: absolute;
	background: white;
	width: 250px;
	border-radius: 5px;
	box-shadow: 2px 4px 13px -5px rgb(99, 123, 124);
}
.beamchange-popup {
	min-height: 80px;
	box-shadow: 2px 4px 13px -5px #f1c670;
}
.popover-arrow {
	left: 0;
	position: absolute;
	top: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%) rotate(45deg);
	height: 14px;
	width: 14px;
}
.sat-arrow {
	left: -1px;
	position: absolute;
	top: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%) rotate(45deg);
	height: 12px;
	width: 12px;
}
.marker-popup-title {
	padding: 10px 10px;
	border-radius: 5px 5px 0 0;
}
.beamchange-popup-title {
	background: linear-gradient(#f8bb43, #f1c670);
	padding: 5px 10px;
}
.marker-popup-title h5 {
	margin: 0;
}
.marker-popup-body {
	min-height: 80px;
	padding: 10px;
}
.marker-stat {
	font-weight: 700;
}
.scroll-note {
	position: absolute;
	color: #777;
	right: 15px;
}
.highlight-blue {
	background: rgba(165, 165, 255, 0.562);
}
</style>
